.button {
  display: block;
  width: max-content;
  padding: 17px 24px;
  color: white;
  background: $textblue;
  border: none;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;

  @include respond-to(mobile) {
    width: auto;
  }
}

.button:active, .button:visited {
  color: white;
}

.button:focus-within,
.button:focus-visible,
.button:focus {
  outline: 2px solid black;
}

.button--yellow {
  color: $primaryblue;
  background: $yellow;
}

.button:disabled {
  opacity: 0.7;
}

.button--white {
  padding: 11px 16px;
  color: $textblue;
  background: white;
  border: 1px solid $grey;
}

.button--white-with-blue-border {
  padding: 15px 25px;
  color: $textblue;
  background: white;
  border: 1px solid $primaryblue;
}

.button--primary-blue {
  color: white;
  background-color: $primaryblue;
}

.button--white:active,
.button--white:visited,
.button--yellow:active,
.button--yellow:visited,
.button--white-with-blue-border:visited {
  color: $primaryblue;
}

.button--full-width {
  width: 100%;
}

.button-container--stick-to-bottom-mobile {
  @include respond-to(mobile) {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px 20px;
    background-color: white;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    z-index: 1;
  }
}

.button--fit-width {
  width: fit-content;
}

.button--disabled {
  background-color: #F1F1F1;
  color: $darkgrey;
  cursor: not-allowed;
}
