.consumer-reservation-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  background-color: white;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  height: 164px;

  @include respond-to(mobile) {
    position: relative;
    z-index: 2;
    scroll-margin-top: 20px;
  }
}

.consumer-reservation-card__body {
  height: 77px;
}

.consumer-reservation-card__location-name {
  color: $textblue;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.consumer-reservation-card__city-name {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 10px;
}

.consumer-reservation-card__arrival-date,
.consumer-reservation-card__arrival-and-exit-times {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.consumer-reservation-card__reservation-days {
  color: #909090;
}

.consumer-reservation-card__last-row {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-top: 20px;
}

.consumer-reservation-card__license-plate {
  display: flex;
  justify-content: center;
  align-items: center;
}

.consumer-reservation-card__license-plate svg {
  margin-right: 5px;
}

.consumer-reservation-card--covered {
  opacity: 0.5;
  background-color: #E8E8E882;
}

.consumer-reservation-card__flag {
  border: 1px solid;
  border-radius: 8px;
  width: fit-content;
  font-size: 12px;
  padding: 2px 8px;
  margin: 8px 0;
}

.consumer-reservation-card__flag--available,
.consumer-reservation-card__flag--upcoming {
  color: #40B443;
  border-color: #40B443;
}

.consumer-reservation-card__flag--available {
  background-color: rgba(64, 180, 67, 0.1);
}

.consumer-reservation-card__flag--cancelled {
  color: #D72C0D;
  border-color: #D72C0D;
  background-color: rgba(215, 44, 13, 0.1);
}

.consumer-reservation-card__flag--future {
  color: #6B6B6B;
  border-color: #6B6B6B;
}
