.checkout-login__title {
  @include respond-to(mobile) {
    margin: 30px 0 40px;
  }
}

.checkout-login__create-account-container {
  justify-content: space-between;
}

.checkout-login__create-account-text {
  font-size: 14px;
  margin-top: 10px;

  @include respond-to(mobile) {
    margin: -16px 0 10px;
  }
}

.checkout-login__create-account-text--link {
  color: $textblue;
  text-decoration: none;
}

.checkout-login__link {
  display: block;
  color: $textblue;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  margin-top: 24px;
  height: 16px;

  @include respond-to(mobile) {
    margin-top: 13px;
  }
}

.checkout-login__login-button {
  margin-top: 32px;
}

.checkout-login__input-button-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 197px;
  margin-top: 15px;

  @include respond-to(mobile) {
    height: auto;
    margin-top: 20px;
  }
}

.checkout-login__continue-button {
  margin: 32px 0 40px;
}
