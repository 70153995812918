select {
  background-color: white;
  border: 1px solid $extra-darkgrey;
  border-radius: 4px;
  padding: 12px;
  font-size: 14px;
  width: 100%;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
}

select + svg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  padding-right: 12px;
}
