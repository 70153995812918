.time-widget.time-widget {
  height: 100%;
  color: $textblack;
  position: relative;
}

.input-field {
  position: relative;
  background-color: white;
}

.time-widget__input {
  position: absolute;
  color: inherit;
}

.time-widget__input::-webkit-inner-spin-button,
.time-widget__input::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.time-widget__options {
  display: none;
  position: absolute;
  width: 100%;
  height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
  top: 100%;
  background-color: white;
  z-index: 99;

  @include respond-to(mobile) {
    border-radius: 5px;
    height: 200px;
    box-shadow: 1px 1px 5px rgb(0 0 0 / 25%);
  }
}

.time-widget__option-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  cursor: pointer;

  @include respond-to(mobile) {
    border-bottom: 1px solid #e5e5e5;
  }
}

.time-widget__option-item--hovering {
  background-color: #e5e5e5;
}

.time-widget__option-item--selected {
  background-color: lightgrey;
}

.time-widget__dropdown-icon {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

.time-widget__dropdown-icon svg {
  margin: 0 14px;
}

.time-widget__dropdown-icon.rotate svg {
  transform: rotate(180deg);
}
