.checkout-steps {
  background-color: white;

  @include respond-to(mobile) {
    background-color: transparent;
  }
}

.checkout-steps__container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 0 auto;

  @include respond-to(mobile) {
    width: 90%;
    padding: 30px 0;
  }
}

.checkout-steps__title {
  width: 100%;
  font-size: 28px;
  font-weight: 500;
  margin: 30px 0;
}

.checkout-steps__steps {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  @include respond-to(mobile) {
    position: relative;
    width: 100%;
    justify-content: space-between;
  }
}

.checkout-steps__step-item {
  display: flex;
  align-items: center;
  margin-right: 15px;

  @include respond-to(mobile) {
    flex-direction: column;
  }
}

.checkout-steps__step-item:first-of-type {
  @include respond-to(mobile) {
    align-items: flex-start;
  }
}

.checkout-steps__step-item:last-of-type {
  margin-right: 0;

  @include respond-to(mobile) {
    align-items: flex-end;
  }
}

.checkout-steps__step-number {
  border: 1px solid $lightgrey;
  border-radius: 50%;
  box-sizing: border-box;
  min-width: 24px;
  line-height: 22px;
  text-align: center;
  font-size: 12px;

  @include respond-to(mobile) {
    border-color: white;
    background-color: white;
    font-size: 14px;
    width: 33px;
    line-height: 31px;
  }
}

.checkout-steps__step-number--selected {
  border-color: $textblue;
  background-color: $textblue;
  color: white;
}

.checkout-steps__step-text {
  font-size: 14px;
  margin: 0 15px 0 10px;

  @include respond-to(mobile) {
    font-size: 10px;
    margin: 15px 0 0;
  }
}

.checkout-steps__step-svg--selected,
.checkout-steps__step-text--selected {
  color: $textblue;
}

.checkout-steps__button-container {
  padding: 20px 30px;
  border-top: 1px solid $lightgrey;
  margin-top: 20px;
}

.checkout-steps__button {
  margin-left: auto;
  min-width: 176px;
}

.checkout-steps__line {
  position: absolute;
  top: 46px;
  left: 0;
  right: 0;
  background-color: $extra-darkgrey;
  height: 1px;
}

.checkbox-form-field label {
  display: flex;
}

.checkbox-form-field input {
  margin-right: 10px;
}
