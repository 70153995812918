.contact-page .page__hero-banner {
  @include respond-to(mobile) {
    display: none;
  }
}

.contact-page__left-section {
  width: 100%;
  margin-right: 20px;

  @include respond-to(mobile) {
    margin-right: 0;
    margin-top: 25px;
  }
}

.about-us-page-card {
  margin-bottom: 25px;
}

.contact-info__box {
  margin-bottom: 18px;
}

.contact-info__box--logo {
  margin-bottom: 0;
  height: 85px;
}

.contact-info__box--logo img {
  height: 100%;
}

.contact-info__box p {
  margin-bottom: 5px;
}

.contact-info__box .contact-action {
  margin-bottom: 10px;
}

.contact-info__box .contact-action a {
  text-decoration: none;
  color: $textblue;
}

.contact-info__box .contact-action--phone a {
  color: $textblack;
}

.contact-info__box .contact-action svg {
  margin-right: 5px;
}

.contact-page .page-card-content {
  padding: 30px;
  font-size: 14px;

  @include respond-to(mobile) {
    padding: 30px 20px;
  }
}
