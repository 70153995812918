.login-page .page-container {
  justify-content: center;
  padding: 80px 0;
  gap: 60px;

  @include respond-to(mobile) {
    padding: 0;
    gap: 0;
  }
}

.login-page .page-card {
  max-width: 560px;
  margin: 0;
}

.login-page .page-card-content {
  font-size: 14px;
  padding: 10px 30px 55px;
}

.login-page__label {
  margin: 12px 0 24px;

  @include respond-to(mobile) {
    margin-top: 0;
  }
}

.login-page__items {
  display: flex;
  flex-direction: column;

  @include respond-to(mobile) {
    margin-bottom: 10px;
    line-height: 28px;
  }
}

.login-page__item {
  margin-bottom: 10px;
}

.login-page__item:last-child {
  margin: 0;

  @include respond-to(mobile) {
    margin-bottom: 24px;
  }
}

.login-page__item svg {
  margin-right: 15px;
}

.login-page__button-container {
  margin-bottom: 43px;

  @include respond-to(mobile) {
    margin: 0;
  }
}

.login-page__card-content {
  justify-content: space-between;
  font-size: 14px;
  font-weight: 400;
}

.login-form-text {
  font-size: 14px;
  margin: 25px 0;
}

.login-form-text--secondary {
  text-align: center;
  color: #9A9A9A;
  margin-bottom: 0;
}

.page-card-content__top-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 175px;
}

.page-card-content__top-section .input-field-container {
  margin-bottom: 24px;
}

.login-page .input-field {
  height: auto;
}
