.password-change-page .password-change-form__fields {
  margin-bottom: 40px;
}

.password-change-page .form-field {
  width: 48%;
  margin-bottom: 20px;

  @include respond-to(tablet) {
    width: 100%;
  }
}

.password-change-page .form-field:first-of-type {
  margin-bottom: 50px;
}

.password-change-form__buttons {
  display: flex;
}

.password-change-page .button {
  margin-right: 10px;

  @include respond-to(mobile) {
    width: 100%;
    margin-right: 0;
  }
}

.password-change-form__cancel-button {
  display: flex;
  justify-content: center;
  align-items: center;

  @include respond-to(mobile) {
    display: none;
  }
}
