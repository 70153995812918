.checkout-payment-form .select-fields {
  padding: 30px 0;
}

.checkout-payment-form .form-field,
.checkout-payment-form .cooldown-period-text {
  padding: 10px 30px;
}

.checkout-payment-form .checkbox-form-field {
  padding-top: 30px;
}

.checkout-payment-form .checkbox-form-field--with-top-border {
  border-top: 1px solid $lightgrey;
}

.checkout-payment-form .form-field__select {
  position: relative;
}

.checkout-payment__container {
  align-items: flex-start;
}

.checkout-payment__terms {
  margin: 10px 0 0;
  padding: 20px 30px;
  flex-direction: row;
  font-size: 14px;
  align-items: center;
  width: auto;

  @include respond-to(mobile) {
    padding: 20px;
  }
}

.checkout-payment__info-title {
  border-bottom: 1px solid $lightgrey;
  padding-bottom: 15px;
  margin-bottom: 20px;
}

.checkout-payment__info-name {
  font-size: 14px;
  margin-bottom: 5px;
}

.checkout-payment__info-title--last {
  margin-top: 60px;
}

.checkout-payment__info-text {
  font-size: 14px;
  margin-bottom: 10px;
}

.checkout-payment__info-license-plates {
  display: flex;
  flex-wrap: wrap;
}

.checkout-payment__info-license-plate {
  width: fit-content;
  padding: 5px 10px;
  background-color: #F1F1F1;
  border-radius: 3px;
  font-size: 14px;
  margin: 10px 10px 10px 0;
}

.checkout-payment__info-license-plate:last-child {
  margin: 10px 0;
}

.checkout-payment__personal-info {
  margin-bottom: 20px;
}

.checkout-payment__personal-info-title {
  @include respond-to(mobile) {
    border-bottom: 0;
  }
}

.checkout-payment__personal-info-edit-link {
  display: block;
  text-decoration: none;
  color: #2a65a8;
  font-size: 14px
}

.checkout-payment__payment-methods-container {
  @include respond-to(mobile) {
    margin-top: 20px;
  }
}

.checkout-payment__toa-link {
  color: $textblue;
}

.cooldown-period-text {
  font-weight: 400;
  font-size: 14px;
  color: $textblack;

  @include respond-to(mobile) {
    margin-bottom: 30px;
  }
}
