.form-error,
.form-field-error {
  font-size: 14px;
  color: red;
}

.form-field--with-errors.form-field--with-errors input,
.form-field--with-errors.form-field--with-errors select {
  border: 1px solid #D72C0D;
  background-color: #FFF4F4;
}

.form-field--with-errors.form-field--with-errors input:focus-within,
.form-field--with-errors.form-field--with-errors select:focus-within,
.form-field--with-errors.form-field--with-errors input:focus-visible,
.form-field--with-errors.form-field--with-errors select:focus-visible,
.form-field--with-errors.form-field--with-errors input:focus,
.form-field--with-errors.form-field--with-errors select:focus {
  outline: 1px solid red;
}

form .label-text {
  display: block;
  margin-bottom: 6px;
  font-size: 14px;
}

textarea,
input,
.input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 12px;
  border: 1px solid $extra-darkgrey;
  border-radius: 4px;
  font-size: 14px;
  background-color: white;
  font-family: inherit;
  -webkit-appearance: none;

  @include respond-to(mobile) {
    max-width: none;
  }
}

input,
.input {
  height: 40px;
}

.input--disabled {
  border-color: #D9D9D9;
  background-color: #F1F1F1;
}

.required-field::before {
  content: '*';
  margin-right: 4px;
  color: $red;
}

.input-error {
  color: $red;
  font-size: 14px;
}

.input-error input {
  border: 1px solid $red;
  background-color: $extra-lightred;
}

.input-container {
  margin-bottom: 30px;
}

.input-field {
  position: relative;
}

input[type=date],
input[type=time],
input[type=checkbox] {
  cursor: pointer;
}

.input-field-label {
  display: block;
  position: relative;
  margin-bottom: 6px;
  font-size: 14px;
}

input[type=checkbox] {
  width: 18px;
  height: 18px;
  -webkit-appearance: checkbox;
}

.time-field,
.input-field {
  height: 100%;
}

@keyframes pulseBackground {
  0% {
    background-color: #DBEEFB;
  }
  100% {
    background-color: transparent;
  }
}

@keyframes pulseText {
  0% {
    color: transparent;
  }
  100% {
    color: initial;
  }
}

.pulse-effect {
  animation: pulseBackground 0.5s ease-in-out, pulseText 0.2s ease-in-out;
}
