.shopping-cart-page .page-card-title--primary {
  @include respond-to(mobile) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    margin: 0;
    font-size: 22px;
    font-weight: 500;
    border: none;
  }
}

.shopping-cart__location-name {
  color: $textblue;
  font-weight: 500;

  @include respond-to(mobile) {
    margin: 0;
  }
}

.shopping-cart__location-address {
  margin-top: 18px;
  font-size: 14px;
}

.shopping-cart__start-date {
  margin-top: 26px;
}

.shopping-cart__end-date {
  margin-top: 20px;
}

.shopping-cart__date {
  font-size: 14px;
  font-weight: 500;
}

.shopping-cart__date-friendly {
  font-size: 14px;
  color: $textgrey;
}

.shopping-cart__duration {
  display: flex;
  margin-top: 20px;
  font-size: 12px;
}

.shopping-cart__duration svg {
  align-self: center;
}

.shopping-cart__edit {
  margin-top: 15px;
  color: $textblue;
  font-size: 14px;
  text-decoration: none;
}

.shopping-cart__total {
  margin-bottom: 18px;
}

.shopping-cart__pricing-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.shopping-cart__pricing-item--grey {
  color: #6B6B6B;
}

.shopping-cart__pricing-item-value {
  font-size: 20px;
  font-weight: 500;
}

.shopping-cart__total-vat {
  font-size: 12px;
  color: #6B6B6B;
}

.shopping-cart__total-discount {
  border: 1px solid #C3E9C4;
  border-radius: 4px;
  padding: 10px 14px;
  background-color: #E7F7E8;
  margin: 10px 0;
}

.empty-shopping-cart {
  @include respond-to(mobile) {
    width: 90%;
    margin: 0 auto;
  }
}

.empty-shopping-cart__text {
  margin: -10px 0 30px;

  @include respond-to(mobile) {
    margin: 12px 0 36px;
    text-align: center;
  }
}

.shopping-cart__cart-items-container {
  @include respond-to(mobile) {
    background: transparent;
    margin-bottom: 0;
  }
}

.shopping-cart__cart-items {
  @include respond-to(mobile) {
    padding: 0;
  }
}

.shopping-cart__cart-item-container {
  margin-bottom: 20px;

  @include respond-to(mobile) {
    border: none;
  }
}

.shopping-cart__cart-item-total {
  border-top: 1px solid $lightgrey;
  padding: 20px;
  font-size: 14px;
  display: flex;

  @include respond-to(mobile) {
    padding: 20px 30px 20px 20px;
  }
}

.shopping-cart__item-total-label {
  margin-right: 5px;
}

.shopping-cart__item-total-without-discount {
  color: #6B6B6B;
  position: relative;
}

.shopping-cart__item-total-without-discount::before {
  content: '';
  width: 75%;
  position: absolute;
  top: calc(50% - 1.5px);
  border-bottom: 1px solid #6B6B6B;
}

.shopping-cart__cart-item-content {
  padding: 30px 20px 20px;

  @include respond-to(mobile) {
    padding: 40px 20px;
  }
}

.shopping-cart__cart-item-title {
  padding: 25px 30px 25px 20px;

  @include respond-to(mobile) {
    padding: 20px;
  }
}

.delete-cart-item-button {
  cursor: pointer;
}
