footer {
  min-height: 100px;
  background-color: white;
  border-top: 1px solid $lightgrey;
  font-size: 14px;

  @include respond-to(mobile) {
    border-top: none;
  }
}

.footer-content {
  width: 80%;
  margin: 0 auto;

  @include respond-to(mobile) {
    width: auto;
    margin: 20px;
  }
}

.footer-top {
  display: flex;
  margin: 20px 0 65px;

  @include respond-to(mobile) {
    flex-direction: column;
    margin-bottom: 40px;
  }
}

.footer-top > * {
  width: 100%;
  margin-right: 50px;

  @include respond-to(mobile) {
    margin-right: 0;
    margin-bottom: 25px;
  }
}

.footer-top > *:last-child {
  margin-right: 0;
  margin-bottom: 0;
}

.footer-bottom {
  display: flex;
  padding: 30px 0;
  border-top: 1px solid $lightgrey;
  justify-content: space-between;

  @include respond-to(mobile) {
    flex-direction: column;
  }
}

.footer-block__title {
  color: $textblue;
  font-weight: 500;
  margin-bottom: 20px;
}

.footer-block-item {
  margin-bottom: 15px;
}

.footer-block-item:last-child {
  margin: 0;
}

.footer-block-item * {
  display: block;
  color: $textblack;
}

.footer-block-item a {
  text-decoration: none;
}

.footer-block-item a:hover {
  text-decoration: underline;
}

.footer-bottom__right {
  @include respond-to(mobile) {
    order: -1;
    margin-bottom: 30px;
  }
}

.footer-bottom__language-button--desktop {
  display: flex;
  align-items: center;
  text-decoration: underline;
  cursor: pointer;

  @include respond-to(mobile) {
    display: none;
  }
}

.footer-bottom__language-button--desktop svg {
  margin-right: 10px;
}

.footer-bottom__language-button--mobile {
  display: none;
  @include respond-to(mobile) {
    display: block;
  }
}

.footer-bottom__language-button--mobile .menu-item--language-button {
  border: none;
}

.footer-bottom__left {
  display: flex;
  align-items: center;

  @include respond-to(mobile) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.footer-bottom__items {
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
  
  @include respond-to(mobile) {
    margin-left: 0;
    margin-top: 10px;
  }
}

.footer-bottom__item > a {
  text-decoration: none;
  color: $textblack;
}

.footer-bottom__item > a:hover {
  text-decoration: underline;
}

.footer-bottom__item::after {
  content: "·";
  margin: 0 10px;
}

.footer-bottom__item:last-of-type::after {
  content: "";
  margin: 0;
}

footer .nested-menu--language .menu-item--language-button {
  border: none;
}

footer .nested-menu--language .menu-item--language-button svg {
  margin-right: 10px;
}

.checkout-confirmation-page .footer-bottom__left,
.checkout-credentials-page .footer-bottom__left,
.checkout-payment-page .footer-bottom__left,
.shopping-cart-page .footer-bottom__left {
  @include respond-to(mobile) {
      margin-bottom: 50px;
  }
}
