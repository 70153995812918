.license-plates-page .page-card-title {
  @include respond-to(mobile) {
    border-bottom: none;
    padding: 35px 20px 20px;
  }
}

.license-plates-page .page-card-content {
  padding: 0;
}

.license-plates-page .license-plate {
  background-color: inherit;
  width: 240px;
  border-color: $extra-darkgrey;

  @include respond-to(mobile) {
    width: 90%;
  }
}

.license-plates-form .form-text {
  width: 460px;
  padding: 10px 30px;
  margin-top: 30px;
  font-size: 14px;
  font-weight: 400;

  @include respond-to(mobile) {
    width: 90%;
    padding: 0;
    margin: 20px auto;
  }
}

.license-plates-form__fields {
  padding: 10px 30px;

  @include respond-to(mobile) {
    padding: 10px 20px;
  }
}

.license-plates-form__fields label {
  display: flex;
  justify-content: center;
  align-items: center;
}

.license-plates-form__fields .label-text {
  display: none;
}

.license-plates-form__bottom {
  margin-top: 70px;
  padding: 30px;
  border-top: 1px solid #E5E5E5;
  display: flex;
  justify-content: flex-end;

  @include respond-to(mobile) {
    padding: 20px;
    margin: 0 0 35px;
    border-top: none;
  }
}

.license-plates-form__bottom .button {
  margin-left: 10px;

  @include respond-to(mobile) {
    width: 100%;
    margin-left: 0;
  }
}

.license-plates-form__bottom .license-plates-form__cancel-button {
  justify-content: center;
  align-items: center;
  display: flex;

  @include respond-to(mobile) {
    display: none;
  }
}

.primary-license-plate-option {
  display: flex;

  @include respond-to(mobile) {
    flex-direction: row-reverse;
  }
}

.primary-license-plate-option .license-plate {
  margin-right: 15px;

  @include respond-to(mobile) {
    margin-right: 0;
  }
}

.new-license-plate-field {
  display: flex;
  flex-wrap: wrap;

  @include respond-to(mobile) {
    flex-direction: row-reverse;
  }
}

.new-license-plate-field .license-plate-input-container {
  @include respond-to(mobile) {
    width: 90%;
  }
}

.new-license-plate-field .license-plate-field__car-icon {
  height: 70px;
  left: 15px;
}

.new-license-plate-field .new-license-plate-field__input {
  width: 240px;
  margin: 15px 15px 15px 0;

  @include respond-to(mobile) {
    width: 100%;
    margin: 15px 0;
  }
}

.primary-license-plate-option--selected .license-plate,
.primary-license-plate-option--selected .new-license-plate-field__input {
  border-color: $primaryblue;
}

.primary-license-plate-option--selected .label-text {
  display: block;
  margin: 0 8px;

  @include respond-to(mobile) {
    display: none;
  }
}

.new-license-plate-field .form-field-error {
  width: 100%;
  margin-top: -10px;
}

.new-license-plate-field .delete-license-plate-btn {
  top: 15px;
  right: 15px;

  @include respond-to(mobile) {
    right: 0;
  }
}

.license-plates-form .add-license-plate-btn {
  width: 240px;
  margin: 15px 15px 15px 0;

  @include respond-to(mobile) {
    width: 100%;
  }
}

.license-plates-form .form-field__checkbox-label {
  @include respond-to(mobile) {
    width: 10%;
    justify-content: flex-start;
  }
}
