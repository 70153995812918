.locations-page__header {
  position: relative;
  margin: 20px 0 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include respond-to(mobile) {
    flex-direction: column;
    margin: 30px 0 20px;
  }
}

.locations-page__back-to-areas {
  position: absolute;
  text-decoration: none;
  color: $textblue;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @include respond-to(mobile) {
    position: initial;
    justify-content: center;
    padding: 10px;
  }
}

.locations-page__back-to-areas .link-text {
  margin: 0 5px;
}

.locations-page__title {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  color: $textblack;
  display: flex;
  align-items: center;
}

.locations-page__items {
  display: grid;
  grid-template-columns: repeat(3, calc(33.33% - 13px));
  grid-gap: 20px;
  margin: 0 auto 20px;

  @include respond-to(1350px) {
    width: 90%;
    grid-template-columns: repeat(2, calc(50% - 10px));
    grid-gap: 20px;
  }
  @include respond-to(1150px) {
    width: 100%;
  }
  @include respond-to(950px) {
    display: flex;
    flex-direction: column;
    max-width: 450px;
  }
  @include respond-to(mobile) {
    width: 90%;
  }
  @include respond-to(mobile) {
    margin-top: 20px;
  }
}

.locations-page__items--centered {
  display: flex;
  justify-content: center;
}

.destination {
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background: white;
  font-size: 14px;
  box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%);
  text-decoration: unset;
  color: $textblack;
}

.locations-page__items--centered .destination {
  width: 400px;

  @include respond-to(mobile) {
    width: 100%;
  }
}

.destination__image-container {
  height: 160px;
  background-color: white;
}

.destination__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.destination__info-container {
  padding: 30px;
}

.destination__header {
  height: 34px;
  display: flex;
  justify-content: space-between;
}

.destination__price {
  min-width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 14px;
  background-color: #F9F9F9;
  border: 1px solid #F9F9F9;
  border-radius: 3px;
}

.destination__price-per-hour {
  margin-left: 4px;
  font-size: 10px;
}

.destination__name {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $textblue;
  font-weight: 500;
  font-size: 16px;
}

.destination__text {
  margin-top: 20px;
  line-height: 20px;
}

.destination--disabled {
  color: $disabled-grey;
  cursor: not-allowed;
  padding-bottom: 100px;
}

.destination--disabled .destination__name.destination__name {
  color: $disabled-grey;
}

.destination--disabled .occupied__sentence {
  position: absolute;
  bottom: 40px;
  right: 30px;
  left: 30px;
  padding: 10px;
  background-color: $extra-lightgrey;
  border: 1px solid $yellow;
  border-radius: 4px;
  line-height: 20px;
  color: $textblack;
}

.destination--disabled .occupied__label {
  position: absolute;
  top: 0;
  left: 30px;
  padding: 10px 15px;
  background-color: white;
  border: 1px solid $grey;
  border-radius: 0 0 4px 4px;
  color: $textblack;

  @include respond-to(mobile) {
    left: 20px;
  }
}

.destination--disabled .destination__image {
  opacity: 0.5;
}

.locations-page .locations-availability-form__section-label {
  color: #8A8A8A;
}

.locations-availability-form-dropdown-header {
  position: relative;
  padding: 20px;
  font-size: 16px;
  color: #2A65A8;
  background-color: white;
}

.shimmer.destination__image-container {
  .destination__image {
    display: none;
  }

  .occupied__label {
    display: none;
  }
}

.shimmer.destination__price {
  color: transparent;
}

.shimmer.shimmer.destination__name {
  margin-right: 10px;
  color: transparent;
}

.destination--disabled .shimmer.destination__name {
  color: transparent;
}

.shimmer.destination__text {
  color: transparent;
}

.shimmer.occupied__sentence {
  display: none;
}
