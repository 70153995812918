$laptop-width: 1200px - 1px;
$tablet-width: 992px - 1px;
$mobile-width: 768px - 1px;

@mixin respond-to($media-args...) {
  @each $media in $media-args {
    @if $media == mobile {
      @media only screen and (max-width: $mobile-width) {
        @content;
      }
    }
    @if $media == tablet {
      @media only screen and (max-width: $tablet-width) {
        @content;
      }
    }
    @if $media == laptop {
      @media only screen and (max-width: $laptop-width) {
        @content;
      }
    }

    // expects width value:
    @media only screen and (max-width: $media-args) {
      @content;
    }
  }
}

.hidden-element.hidden-element {
  display: none !important;
}

.centered-bg-image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.cursor-pointer {
  cursor: pointer;
}

.reversed-order-on-mobile {
  @include respond-to(mobile) {
    order: -1;
  }
}

.mobile-only.mobile-only {
  display: none;

  @include respond-to(mobile) {
    display: block;
  }
}

.desktop-only.desktop-only {
  @include respond-to(mobile) {
    display: none;
  }
}

.flash-message {
  margin: 10px auto;
  left: calc(50% - 230px);
  position: absolute;
  width: 460px;
  min-height: 52px;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #c3dae9;
  background-color: #e7f0f7;
  z-index: 1;

  @include respond-to(mobile) {
    top: 33px;
    width: 80%;
    left: calc(50% - 40%);
  }
}

.flash-message--success {
  border: 1px solid #C3E9C4;
  background-color: #E7F7E8;
}

.flash-message__text {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  padding: 0 20px;
  margin: 0;
}

.flash-message__close-button {
  height: 12px;
  margin: 0 22px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.shimmer.shimmer {
  animation: shimmerAnimation 1.5s infinite linear;
  background: linear-gradient(to right, rgb(245, 245, 245, 0.5) 4%, #dedede 25%, rgb(245, 245, 245, 0.5) 36%);
  background-size: 1000px 100%;
}

:not(input).shimmer.shimmer {
  border-radius: 4px;
}

@keyframes shimmerAnimation {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
