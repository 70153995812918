.cancel-reservation-decision {
  display: none;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 0 0 5px 5px;
}

.cancel-reservation-decision__transparent-header {
  background-color: transparent;
  height: 35%;
}

.cancel-reservation-decision__body {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  height: 65%;
  border-radius: 0 0 5px 5px;
}

.cancel-reservation-decision__question,
.cancel-reservation-decision__refund {
  font-size: 14px;
  font-weight: 400;
  padding: 5px 16px;
}

.cancel-reservation-decision__question {
  padding-top: 10px;
}

.cancel-reservation-decision__refund {
  display: flex;
  justify-content: space-between;
}

.cancel-reservation-decision__refund-price {
  color: #40B443;
  font-weight: 600;
}

.cancel-reservation-decision__refund-price--grey {
  color: grey;
}

$border-color: #F1F1F1;
$button-height: 48px;

.cancel-reservation-decision__loader,
.cancel-reservation-decision__buttons {
  display: flex;
  height: $button-height;
  border-top: 1px solid $border-color;
  margin-top: 5px;
  border-radius: 0 0 5px 5px;
}

.cancel-reservation-decision__loader {
  display: none;
  justify-content: center;
  align-items: center;
}

.cancel-reservation-decision__keep-reservation-button,
.cancel-reservation-decision__cancel-reservation-button {
  width: 50%;
  border: none;
  background-color: white;
  font-size: 14px;
  cursor: pointer;
}

.cancel-reservation-decision__keep-reservation-button:hover,
.cancel-reservation-decision__cancel-reservation-button:hover,
.cancel-reservation-decision__confirmation-button:hover {
  background-color: #EDF1FB;
}

.cancel-reservation-decision__keep-reservation-button {
  border-right: 0.5px solid $border-color;
  border-radius: 0 0 0 5px;
}

.cancel-reservation-decision__cancel-reservation-button {
  border-left: 0.5px solid $border-color;
  border-radius: 0 0 5px 0;
  color: #174EA0;
  font-weight: 600;
  padding: 0;
}

.cancel-reservation-decision__loader-circle {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top-color: $textblue;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.cancel-reservation-decision__confirmation {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 3;
  background-color: white;
  border-radius: 5px;
}

.cancel-reservation-decision__confirmation-text {
  padding: 0 16px;
  height: calc(100% - $button-height);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cancel-reservation-decision__confirmation-title,
.cancel-reservation-decision__confirmation-button {
  color: $textblue;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
}

.cancel-reservation-decision__confirmation-title {
  padding: 5px 0;
}

.cancel-reservation-decision__confirmation-title--error {
  color: red;
}

.cancel-reservation-decision__confirmation-explanation {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  padding: 5px 0;
}

.cancel-reservation-decision__confirmation-explanation a {
  color: $textblack;
}

.cancel-reservation-decision__confirmation-button {
  background-color: white;
  width: 100%;
  border: 0;
  border-top: 1px solid $border-color;
  height: $button-height;
  cursor: pointer;
  border-radius: 0 0 5px 5px;
}
