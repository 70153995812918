.my-account-page .page-card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include respond-to(mobile) {
    padding: 16px;
  }
}

.consumer-transactions__jump-to-date {
  position: relative;
  width: 156px;
  cursor: pointer;
}

.consumer-transactions__jump-to-date-input {
  cursor: pointer;
}

.consumer-transactions__jump-to-date-input::-webkit-calendar-picker-indicator {
  display: none;
}

.consumer-transactions__jump-to-date-icon {
  position: absolute;
  top: 13px;
  right: 13px;
  display: flex;
  width: 14px;
  height: 14px;
}

.consumer-transactions__jump-to-date-icon svg {
  width: 100%;
  height: 100%;
}

.my-account-page .page-container {
  justify-content: center;
  gap: 20px;

  @include respond-to(mobile) {
    gap: 0;
  }
}

.my-account-page .page-container--column {
  align-items: center;
}

.my-account-page .page-card {
  width: 49%;
  max-width: 650px;
  height: fit-content;
  margin: 0;
}

.page-container--column .page-card {
  width: 100%;

  @include respond-to(mobile) {
    width: 95%;
    background-color: white;
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 40px;
    }
  }
}

.my-account-page .page-card-content {
  padding: 15px 30px;

  @include respond-to(mobile) {
    padding: 15px 20px;
  }
}

.my-account-page .page-card-content__section {
  background-color: white;
  border: none;
}

.my-account-page .page-card-content__section-edit-link {
  margin-top: 10px;
}

.my-account-page .license-plates-container {
  display: flex;
  flex-wrap: wrap;
}

.my-account-page .license-plate {
  margin: 5px 7px 5px 0;
  min-width: 116px;
  width: fit-content;
  background-color: #F1F1F1;
  border: 1px solid #F1F1F1;
  border-radius: 4px;
  height: 40px;
  display: flex;
  align-items: center;
  position: relative;
}

.my-account-page .license-plate--selected {
  border-color: $primaryblue;
  background-color: inherit;
  font-weight: 700;
}

.my-account-page .license-plate__value {
  margin-left: 40px;
  margin-right: 15px;
}

.my-account-page .page-card-content--consumer-transactions {
  max-height: 600px;
  overflow-y: auto;
  padding: 30px;
  margin-right: 10px;
}

.page-container--column .page-card-content--consumer-transactions {
  max-height: unset;
  margin-right: 0;

  @include respond-to(mobile) {
    padding: 15px;
  }
}

.page-card-content--consumer-transactions::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

.page-card-content--consumer-transactions::-webkit-scrollbar-track {
  background-color: transparent;
  margin: 15px;
}

.page-card-content--consumer-transactions::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
  height: 10px;
}

.consumer-transactions-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  text-align: center;
}

// ========== SPINNER START ========================================
@-webkit-keyframes rotate {
  to {
    transform: rotate3d(0, 0, 1, 360deg);
  }
}

@keyframes arc {
  from {
    stroke-dasharray: 0 150;
    stroke-dashoffset: 0;
  }
  to {
    stroke-dasharray: 100 150;
    stroke-dashoffset: -140;
  }
}

@keyframes rotate {
  to {
    transform: rotate3d(0, 0, 1, 360deg);
  }
}

.spinner {
  height: 30px;
  border-radius: 50%;
}

.spinner__ring {
  stroke: lightblue;
  stroke-width: 3px;
  opacity: 0.3;
}

.spinner__line {
  stroke: $primaryblue;
  stroke-width: 3px;
  stroke-linecap: round;
  transform-origin: 50% 50%;
  transform: rotate3d(0, 0, 1, 0deg);
  animation: 2156ms arc ease-in-out infinite, 1829ms rotate linear infinite;
}

// ========== SPINNER END ==========================================

.consumer-transactions-loader__message {
  font-size: 14px;
  font-weight: 400;
}

.consumer-transactions-button {
  width: 308px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: $textblue;
  border: 1px solid $grey;
  border-radius: 5px;
  margin: 16px auto;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;

  @include respond-to(mobile) {
    width: 100%;
  }
}

.consumer-reservations-end-of-list {
  font-size: 14px;
  font-weight: 400;
  color: $textblack;
  text-align: center;
  margin-top: 30px;

  @include respond-to(mobile) {
    margin-bottom: 15px;
  }
}
