.location_features {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 30px;
}

.location_features__feature {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  width: fit-content;
}

.location_features__feature--2-desktop-columns {
  display: grid;
  grid-template-columns: 50% 50%;

  @include respond-to(mobile) {
    display: flex;
  }
}

.location_features__feature:last-child {
  margin-bottom: 0;
}

.location_features__feature svg {
  margin-right: 8px;
}

.shimmer.location_features__features {
  color: transparent;
}

.shimmer.location_features__feature {
  color: transparent;
}
