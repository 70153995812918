header {
  min-height: 90px;
  background-color: white;

  @include respond-to(mobile) {
    min-height: 52px;
    max-height: 52px;
    border-bottom: none;
  }
}

.navbar {
  height: 100%;
  margin: 0 auto;
  background-color: #DEE5EF;
}

.navbar--desktop {
  padding: 0 10%;
  display: flex;

  @include respond-to(mobile) {
    display: none;
  }
}

.navbar--mobile {
  display: none;

  @include respond-to(mobile) {
    display: flex;
  }
}

.branding {
  margin: auto 20px auto 0;
  color: $textblue;
  cursor: pointer;
  text-decoration: none;
}

.branding img {
  max-height: 42px;
}

.branding label {
  cursor: inherit;
}

.menu {
  height: 100%;
  display: flex;
}

.navbar--mobile .menu--left {
  width: 15%;
}

.navbar--mobile .menu--right {
  width: 35%;
  justify-content: flex-end;
}

.navbar--mobile .menu--right .menu-item--icon-only:last-of-type {
  margin-right: 20px;
}

.navbar--mobile .menu--right .menu-item--icon-only {
  width: 40px;
  margin: 0;
}

.menu:last-of-type {
  margin: auto 0 auto auto;

  @include respond-to(mobile) {
    margin: 0;
  }
}

.menu-item {
  height: 100%;
  margin: auto 20px;
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
}

.menu-item--icon-only {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 100%;
}

.menu-item--close {
  margin: auto 0 auto auto;
  justify-content: space-between;
  font-size: 16px;
}

.menu-item--close .menu-item-label {
  font-weight: 500;
}

.navbar .menu-item {
  color: $textblue;
}

.navbar--desktop .menu-item svg {
  margin: auto 12px;
}

.nested-menu {
  display: flex;
}

.nested-menu--language .menu-item--language-button {
  margin: 0;
  border-top: 1px solid $lightgrey;
}

.nested-menu__items {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: white;
  z-index: 99;
}

.nested-menu__bottom-items {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.nested-menu__close-button {
  height: 100%;
  min-width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nested-menu__items .menu-item {
  margin: 0;
  padding-left: 20px;
  color: $textblack;
  height: 53px;
  border-bottom: 1px solid $lightgrey;
}

.nested-menu__items button.menu-item-label {
  border: none;
  background-color: inherit;
  padding: 0;
  color: inherit;
  width: 100%;
  height: 100%;
  text-align: left;
}

.nested-menu__items .menu-item:not(.menu-item--close):not(.menu-item--button) {
  width: 100%;
}

.nested-menu__items .menu-item:not(.menu-item--close):not(.menu-item--button) svg {
  margin-right: 10px;
}

.menu-item--button,
.menu-item.menu-item--button {
  border: 1px solid $lightgrey;
  border-radius: 4px;
  margin: 10px 20px;
  width: auto;
}

.menu-item--button:first-of-type {
  margin-top: 20px;
}

.menu-item--button-selected {
  background-color: $textblue;
  border-color: $textblue;
}

.menu-item-label {
  cursor: pointer;
}

.menu-item--button-selected .menu-item-label {
  color: white;
}

.menu-item:hover .menu-item-dropdown {
  opacity: 1;
  visibility: visible;
  transform: none;
}

$dropdown-width: 250px;

.menu-item-dropdown {
  position: absolute;
  min-width: $dropdown-width;
  top: 100%;
  left: -5px;
  background-color: #DEE5EF;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  z-index: 11;
}

.loaded .menu-item-dropdown {
  transition: 0.3s ease-in-out;
  transition-property: opacity, visibility, transform;
}

$dropdown-item-horizontal-padding: 40px;

.menu-item-dropdown__item {
  display: block;
  padding: 15px calc($dropdown-item-horizontal-padding / 2);
  width: $dropdown-width - $dropdown-item-horizontal-padding;
  border-bottom: 1px solid #c7c7c7;
  color: $textblack;
  font-size: 14px;
  text-decoration: none;
}

button.menu-item-dropdown__item {
  width: $dropdown-width;
  border: none;
  background-color: inherit;
  text-align: left;
  cursor: pointer;
}

.menu-item-dropdown__item:first-child {
  border-top: 1px solid #c7c7c7;
}

.menu-item-dropdown__item:last-child {
  border-bottom: none;
}

.menu-item-dropdown__item-title {
  font-weight: 500;
  font-size: 16px;
}

.menu-item.menu-item--without-border {
  border: none;
}

.navbar--mobile .branding {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 0 10px;
}

.navbar--mobile .branding img {
  max-width: 90%;
}
