main {
  flex: auto;
  background-color: $extra-lightgrey;
}

.main-content {
  margin: 0;
  min-height: 80vh;
  background-position: center;
  background-size: cover;
}

.main-content .container {
  width: 80%;
  margin: 0 auto;
  padding: 40px 0;

  @include respond-to(mobile) {
    width: 100%;
    padding: 0;
  }
}

.flash-message {
  margin: 0 auto;
  top: 55px;
  left: calc(50% - 230px);
  position: fixed;
  z-index: 11;
  width: 460px;
  min-height: 52px;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include respond-to(mobile) {
    top: 33px;
    width: 80%;
    left: calc(50% - 40%);
  }
}

.flash-message--success {
  border: 1px solid #C3E9C4;
  background-color: #E7F7E8;
}

.flash-message--error {
  border: 1px solid #D72C0D;
  background-color: #FFF4F4;
}

.flash-message__text {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  padding: 12px 20px;
}

.flash-message__icon {
  height: 20px;
  margin: 0 22px;
}

.flash-message__icon svg {
  height: 100%;
  width: 100%;
}

.flash-message--success .flash-message__icon {
  color: #249026;
}

.flash-message__close-button {
  height: 12px;
  margin: 0 22px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.flash-message__close-button svg {
  height: 100%;
  width: 100%;
}
