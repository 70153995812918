.consumer-reservation-card-menu {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 3;

  @include respond-to(mobile) {
    position: relative;
    top: unset;
    right: unset;
    width: 100%;
    z-index: 1;
  }
}

.consumer-reservation-card-menu__button {
  display: none;
  width: 32px;
  height: 32px;
}

.consumer-reservation:hover .consumer-reservation-card-menu__button {
  display: block;

  @include respond-to(mobile) {
    display: none;
  }
}

$clickable-item-hover-color: #EDF1FB;
$clickable-item-active-color: #D1E3FF;

.consumer-reservation-card-menu__button:hover svg rect:first-of-type {
  fill: $clickable-item-hover-color;
}

.consumer-reservation-card-menu__button.active {
  display: block;

  svg rect:first-of-type {
    fill: $clickable-item-active-color;
  }
}

.consumer-reservation-card-menu__items {
  display: none;
  position: absolute;
  background-color: white;
  z-index: 1;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  width: 238px;
  border-radius: 5px;

  @include respond-to(mobile) {
    position: relative;
    width: 100%;
    border-radius: 0 0 5px 5px;
  }
}

.consumer-reservation-card-menu__items.active {
  display: block;
}

.consumer-reservation-card-menu__item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e1e1e1;
  text-decoration: none;

  svg {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }

  p {
    margin-top: 2px;
    color: $textblack;
  }
}

.consumer-reservation-card-menu__item:first-of-type {
  border-radius: 5px 5px 0 0;

  @include respond-to(mobile) {
    margin-top: -5px;
    padding-top: 18px;
  }
}

.consumer-reservation-card-menu__item:last-of-type {
  border-bottom: none;
  border-radius: 0 0 5px 5px;
}

.consumer-reservation-card-menu__item:hover {
  background-color: $clickable-item-hover-color;

  @include respond-to(mobile) {
    background-color: white;
  }
}

.consumer-reservation-card-menu__item.active {
  background-color: $clickable-item-active-color;
}
