.license-plate-fields-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.license-plate-input-container {
  position: relative;
}

.license-plate-field__car-icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  left: 15px;
}

.license-plate-input-container input {
  padding-left: 40px;
}

.license-plate-field {
  width: calc(50% - 12px);
  margin-bottom: 24px;

  @include respond-to(mobile) {
    width: 100%;
  }
}

.license-plate-field .label-text::before {
  margin-right: 4px;
}

.add-license-plate-btn {
  width: calc(50% - 12px);

  @include respond-to(mobile) {
    width: 40px;
    height: 40px;
    padding: 0;
    line-height: 38px;
  }
}

.license-plates-form-error {
  margin-bottom: 20px;
  color: red;
  font-size: 14px;
}

.license-plate-input--with-errors input {
  border: 1px solid #D72C0D;
  background-color: #FFF4F4;
}

.license-plate-input-error {
  font-size: 14px;
  color: red;
}

.delete-license-plate-btn {
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  cursor: pointer;
}
