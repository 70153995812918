.locations-availability-form__container {
  padding: 13px 10% 39px;
  background-color: white;

  @include respond-to(mobile) {
    padding: 35px 20px;
  }
}

.locations-availability-form__container .form-field-error {
  @include respond-to(mobile) {
    font-size: 12px;
  }
}

.locations-availability-form__content {
  display: flex;
  justify-content: space-between;
  align-items: end;
  width: 100%;

  @include respond-to(1150px) {
    flex-direction: column;
  }
}

.locations-availability-form__section {
  display: flex;
  flex-direction: column;
  flex-basis: 32%;
  font-size: 14px;

  @include respond-to(1150px) {
    flex-basis: unset;
    margin-right: 0;
    margin-bottom: 12px;
    width: 100%;
  }
}

.locations-availability-form__section--with-errors {
  @include respond-to(1150px) {
    margin-bottom: 20px;
  }
  @include respond-to(mobile) {
    margin-bottom: 30px;
  }
}

.locations-availability-form__content--two-sections .locations-availability-form__section {
  flex-basis: 48%;
}

.locations-availability-form__content--three-sections .locations-availability-form__section {
  flex-basis: 32%;
}

.locations-availability-form__content--four-sections .locations-availability-form__section {
  flex-basis: 24%;
}

.locations-availability-form__section:last-child {
  margin-right: 0;
  justify-content: space-between;

  @include respond-to(mobile) {
    margin-bottom: 0;
    margin-top: 15px;
  }
}

.locations-availability-form__section .button {
  height: 50px;
}

.locations-availability-form__section-label {
  margin-bottom: 6px;
}
