.modal {
  display: none;
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.modal.opened {
  display: flex;
}

.modal__content {
  margin: auto;
  background: white;
  width: 520px;

  @include respond-to(mobile) {
    position: fixed;
    width: 100%;
    height: 100%;
  }
}

.modal__close {
  cursor: pointer;
}

.modal__header {
  display: flex;
  justify-content: space-between;
  padding: 16px 20px;
  border-bottom: 1px solid $lightgrey;
  font-weight: 500;
}
