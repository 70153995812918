.destination-card {
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  margin-right: 24px;
  width: calc(33% - 12px);
  cursor: pointer;

  @include respond-to(mobile) {
    flex-shrink: 0;
    scroll-snap-align: center;
    width: 80%;
    margin: 0 10px;
  }
}

.destination-card:nth-child(3n) {
  margin-right: 0;
}

.destination-card:first-of-type {
  @include respond-to(mobile) {
    margin-left: 20px;
  }
}

.destination-card:last-of-type {
  @include respond-to(mobile) {
    margin-right: 20px;
  }
}

.destination-card:last-of-type span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: $textgrey;
  font-size: 12px;
  font-weight: 500;
}

.destination-card-image {
  width: 100%;
  object-fit: cover;
  max-height: 222px;
  flex-basis: 100%;
}

.destination-card-name {
  padding: 30px 30px 15px;
  font-size: 16px;
  font-weight: 500;
  color: $textblue;

  @include respond-to(mobile) {
    padding: 12px 12px 10px;
  }
}

.destination-card-price {
  display: block;
  padding: 0 30px 30px;
  font-weight: 500;
  font-size: 12px;
  color: $textblack;

  @include respond-to(mobile) {
    padding: 0 12px 20px;
  }
}
