.pagination {
  display: flex;
  justify-content: center;
  margin: 45px 0 90px;
}

.pagination__page-numbers {
  display: flex;
}

.pagination__previous-page,
.pagination__page-number,
.pagination__next-page {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 1px solid $lightgrey;
  border-radius: 4px;
  background: $extra-lightgrey;
  color: $primaryblue;
  margin: 3px;
  cursor: pointer;
}

.pagination__previous-page,
.pagination__next-page {
  padding: 10px 15px;
}

.pagination__previous-page--disabled,
.pagination__next-page--disabled {
  color: $darkgrey;
  cursor: default;
}

.pagination__page-number {
  text-decoration: none;
  color: $textblue;
  box-sizing: border-box;
  min-width: 40px;
  min-height: 40px;
}

.pagination__page-number--current {
  color: white;
  background: $darkblue;
}
