.license-plate {
  margin: 15px 0;
  min-width: 116px;
  width: fit-content;
  background-color: #F1F1F1;
  border: 1px solid #F1F1F1;
  border-radius: 4px;
  height: 40px;
  display: flex;
  align-items: center;
  position: relative;
}

.license-plate__car-icon {
  position: absolute;
  left: 15px;
}

.license-plate__value {
  margin-left: 40px;
  margin-right: 15px;
}

.contact-item {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin: 8px 0;
}

.contact-item svg {
  margin-right: 10px;
}
