html, body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

body {
  display: flex;
  flex-direction: column;
  font-family: Roboto, sans-serif;
  font-weight: 400;
}

.hidden-on-mobile {
  @include respond-to(mobile) {
    display: none;
  }
}

p, button, input {
  margin: 0;
}

.bold-text {
  font-weight: 900;
}

@import "main";
@import "footer";
@import "buttons";
@import "pagination";
@import "modal";
@import "page";
@import "form";
