.faq-page .container.page-container {
  flex-direction: column;
  padding: 0;
}

.faq-page .faq-page__items {
  width: 800px;
  margin: 0 auto 100px;

  @include respond-to(tablet) {
    width: 90%;
  }
}

.faq-page .faq-item:not(:first-of-type) {
  border-top: 1px solid $lightgrey;
}

.faq-page .faq-item {
  cursor: pointer;
}

.faq-page .faq-item__title {
  background-color: white;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
}

.faq-page .faq-item__title p {
  padding: 15px 20px;
}

.faq-page .faq-item__arrow {
  min-width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.faq-page .faq-item__arrow.rotate {
  transform: rotate(180deg);
}

.faq-page .faq-item .faq-item__body {
  display: none;
  padding: 0 20px 20px;
  background-color: white;
  font-size: 14px;
  font-weight: 400;
}
