@import "card";
@import "card_menu";
@import "cancel_reservation_decision";

$payment-item-width: 280px;
$payment-item-margin: 12px;
$payment-item-padding: 16px;
$payment-items-padding: 10px;
$payment-items-max-width: calc(
        $payment-item-width * 4
        + $payment-item-margin * 8
        + $payment-item-padding * 8
        + $payment-items-padding * 2
);

.consumer-reservations {
  display: grid;
  grid-template-columns: auto auto auto auto;
  width: fit-content;
  max-width: $payment-items-max-width;
  margin: 0 auto 50px;
  padding: 0 $payment-items-padding;

  @include respond-to(1350px) {
    grid-template-columns: auto auto auto;
  }

  @include respond-to(1100px) {
    grid-template-columns: auto auto;
  }

  @include respond-to(mobile) {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 100%;
  }
}

.consumer-reservation {
  position: relative;
  width: $payment-item-width;
  min-width: $payment-item-width;
  margin: $payment-item-margin;
  color: $textblack;

  @include respond-to(mobile) {
    width: 90%;
    margin: $payment-item-margin auto;
  }
}
