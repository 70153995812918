.password-set-page .page-container {
  padding: 80px 0;
  justify-content: center;

  @include respond-to(mobile) {
    padding: 0;
  }
}

.page-card--password-set {
  max-width: 560px;
  margin: 0;
}

.password-set-form {
  padding: 10px 30px 55px;;
}

.password-set-form__field {
  margin: 36px auto;
}

.password-set-form__expired-link-text {
  padding: 5px 0;
  margin: 20px 0 60px 0;
  font-size: 14px;
  font-weight: 400;
}
