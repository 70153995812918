// Colors:
$textblack: #323232;
$textblue: #2A65A8;
$textgrey: #8C8C8C;
$primaryblue: #174EA0;
$darkblue: #02265B;
$extra-lightgrey: #F9F9F9;
$lightgrey: #E5E5E5;
$grey: #DEDEDE;
$darkgrey: #BBBBBB;
$extra-darkgrey: #BABABA;
$yellow: #F7CA52;
$extra-lightyellow: #FDF7E7;
$darkyellow: #dda303;
$darkred: #CB0404;
$red: #D72C0D;
$lightred: #FDE8E7;
$extra-lightred: #FFF4F4;
$pink: #F19B94;
$disabled-grey: #797979;
$darkgreen: #0A6A0A;
$extra-lightgreen: #E7F7E8;
$lightgreen: #C3E9C4;
