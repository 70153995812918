.home-page__header {
  margin-bottom: -50px;

  @include respond-to(mobile) {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }
}

.home-page--without-destinations .home-page__header {
  margin-bottom: -80px;
  background-color: white;

  @include respond-to(mobile) {
    margin-bottom: 0;
  }
}

.home-page__header-title {
  position: relative;
}

.home-page__hero-banner {
  max-height: 310px;
  width: 100%;
  object-fit: cover;

  @include respond-to(mobile) {
    height: 320px;
  }
}

.home-page__title {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 89px;
  color: white;
  font-size: 42px;
  font-weight: 500;
  text-align: center;
  max-width: 650px;
  height: fit-content;
  margin: auto;

  @include respond-to(mobile) {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 32px;
    padding: 0 30px;
  }
}

.home-page__card-row {
  padding-bottom: 20px;

  @include respond-to(mobile) {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.home-page__card-row-header {
  position: relative;
  width: 80%;
  margin: 0 auto;
}

.home-page__card-row-title {
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  padding: 35px;

  @include respond-to(mobile) {
    font-size: 18px;
    margin-top: 50px;
  }
}

.home-page__show-all-link {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;

  @include respond-to(mobile) {
    position: relative;
    margin-top: 25px;
    text-align: center;
  }
}

.home-page__show-all-link a {
  color: $textblue;
}

.home-page__popular-destinations {
  display: flex;
  justify-content: center;
  width: 80%;
  margin: 0 auto;

  @include respond-to(mobile) {
    width: 100%;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    padding-bottom: 8px;
    justify-content: flex-start;
  }
}

.home-page__inputs .input-error {
  flex-basis: 100%;
}

.home-page__inputs p {
  margin: 8px 0;
}

.home-page__location-area-field {
  height: 50px;
  position: relative;
}

.home-page__location-area-field select {
  height: 100%;
  padding: 12px 45px;
  background: transparent;
  position: absolute;
  z-index: 2;
}

.location-area-field__location-pin-icon,
.location-area-field__carrot-icon {
  top: 0;
  position: absolute;
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.location-area-field__location-pin-icon {
  left: 0;
}

.location-area-field__location-pin-icon svg {
  height: 16px;
  width: 16px;
}

.location-area-field__carrot-icon {
  right: 0;
}

.home-page__datetime-fields {
  height: 50px;
  display: flex;
  justify-content: space-between;
}

.home-page__datetime-fields .date-field,
.home-page__datetime-fields .time-field {
  width: 49%;
}

.home-page__datetime-fields .date-field input,
.home-page__datetime-fields .time-field input {
  height: 100%;
}

.home-page__check-availability-title {
  width: 100%;
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 15px;
}

.home-page__explanation {
  background-color: white;

  @include respond-to(mobile) {
    background-color: transparent;
    margin-bottom: 20px;
  }
}

.home-page__explanation-title {
  text-align: center;
  padding: 80px 40px 40px;
  font-size: 20px;
  font-weight: 500;

  @include respond-to(mobile) {
    margin: 0;
    padding: 70px 0 30px;
    font-size: 18px;
  }
}

.home-page__explanations-list {
  display: flex;
  justify-content: space-between;
  width: 75%;
  margin: 0 auto;
  padding-bottom: 117px;

  @include respond-to(1400px) {
    width: 80%;
  }
  @include respond-to(1350px) {
    width: 85%;
  }
  @include respond-to(1300px) {
    width: 90%;
  }
  @include respond-to(mobile) {
    flex-direction: column;
    padding-bottom: 0;
  }
}

.home-page__explanation-block {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 2%;
  margin: 0 1%;
  color: $textblue;
  border: 1px solid $lightgrey;
  font-size: 14px;

  @include respond-to(1200px) {
    flex-direction: column;
    text-align: center;
  }
  @include respond-to(mobile) {
    flex-direction: row;
    background-color: white;
    border: none;
    width: auto;
    margin: 0 0 10px;
  }
}

.home-page__explanation-block-number {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  min-width: 40px;
  border-radius: 20px;
  border: 1px solid $grey;
  margin-right: 20px;

  @include respond-to(1200px) {
    margin-right: 0;
    margin-bottom: 20px;
  }
  @include respond-to(mobile) {
    margin: 20px;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
  }
}

.home-page__explanation-block-text {
  @include respond-to(mobile) {
    font-size: 14px;
    font-weight: 500;
  }
}

.home-page__pricing-and-benefits-button {
  display: block;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  padding: 4px;
  background-color: white;
  border: 1px solid $extra-darkgrey;
  border-radius: 10px;
  margin-right: 10px;
}

.home-page__pricing-and-benefits-button:last-of-type {
  margin-right: 0;
}

.home-page__pricing-and-benefits-button:checked {
  position: relative;
  appearance: none;
  background: $primaryblue;
  border: 1px solid $primaryblue;
  border-radius: 50%;
}

.home-page__pricing-and-benefits-button:checked::before {
  content: " ";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 3px solid white;
  border-radius: 50%;
}

.home-page__pricing-and-benefits {
  margin: 80px 0;
  overflow: hidden;

  @include respond-to(mobile) {
    margin-top: 70px;
  }
}

.home-page__pricing-and-benefits-title {
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  margin: 0;

  @include respond-to(mobile) {
    margin-bottom: 25px;
  }
}

.home-page__pricing-and-benefits-carousel {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  max-width: 940px;
  margin: 0 auto;

  @include respond-to(mobile) {
    width: 90%;
  }
}

.home-page__pricing-and-benefits-card {
  display: flex;
  width: 100%;
  flex-shrink: 0;
  margin: 45px 100px 25px 0;
  background-color: white;
  border: 1px solid $lightgrey;
  box-sizing: border-box;
  padding: 35px;
  scroll-snap-align: start;

  @include respond-to(mobile) {
    display: block;
    padding-left: 0;
    padding-right: 0;
    border: none;
    padding-top: 27px;
  }
}

.home-page__pricing-and-benefits-card-left {
  flex: 1 1 0;
  width: 100%;
  border-right: 1px solid $lightgrey;
  text-align: center;
  padding: 0 27.5px;

  @include respond-to(mobile) {
    padding: 0;
    border-right: none;
    border-bottom: 1px solid $lightgrey;
  }
}

.home-page__pricing-and-benefits-card-left > p:last-of-type {
  @include respond-to(mobile) {
    margin-bottom: 40px;
  }
}

.home-page__pricing-and-benefits-card-left-title {
  padding-top: 25px;
  font-weight: 500;
  font-size: 18px;

  @include respond-to(mobile) {
    margin: 0 0 40px;
    padding: 0 20px;
  }
}

.home-page__pricing-and-benefits-card-left-text {
  margin-bottom: 15px;
  color: $textgrey;
  font-size: 12px;

  @include respond-to(mobile) {
    font-size: 14px;
    margin: 0;
  }
}

.home-page__pricing-and-benefits-card-left-price {
  color: $textblue;
  font-weight: 500;
  font-size: 42px;
  margin: 25px 0;

  @include respond-to(mobile) {
    margin: 8px 0 15px;
  }
}

.home-page__pricing-and-benefits-card-right {
  flex: 1 1 0;
  width: 100%;
  margin-left: 55px;
  margin-top: 15px;
  font-size: 14px;

  @include respond-to(mobile) {
    margin-left: 0;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    width: auto;
  }
}

.home-page__pricing-and-benefits-card-right-title {
  margin-bottom: 30px;
  padding-right: 50px;

  @include respond-to(mobile) {
    margin: 15px 0 0;
    padding-right: 0;
  }
}

.home-page__pricing-and-benefits-card-right__benefits {
  display: flex;
  align-items: center;
  margin-bottom: 9px;

  @include respond-to(mobile) {
  }
}

.home-page__pricing-and-benefits-card-right__benefits svg {
  margin-right: 10px;
}

.home-page__pricing-and-benefits-card-right__benefits:last-of-type {
  margin-bottom: 0;
}

.home-page__pricing-and-benefits-card-right__benefits:first-of-type {
  @include respond-to(mobile) {
    margin-top: 32px;
  }
}

.home-page__pricing-and-benefits-card-right__benefits > p {
  margin: 0;
}

.home-page__pricing-and-benefits-navigation {
  display: flex;
  justify-content: center;
}

.home-page__pricing-and-benefits-carousel::-webkit-scrollbar,
.home-page__popular-destinations::-webkit-scrollbar {
  display: none;
}

.home-page .loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top-color: $textblue;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.home-page .locations-availability-form__container {
  position: relative;
  width: 80%;
  margin: auto;
  padding: 34px 50px 60px;
  box-sizing: border-box;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  top: -85px;

  @include respond-to(1500px) {
    width: 85%;
  }
  @include respond-to(1300px) {
    width: 95%;
  }
  @include respond-to(1150px) {
    width: 80%;
  }
  @include respond-to(mobile) {
    top: unset;
    order: -1;
    transform: none;
    width: 100%;
    padding: 35px 20px;
  }
}

.home-page .consumer-reservations {
  margin-bottom: 0;

  @include respond-to(mobile) {
    flex-direction: row;
    width: 100%;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    padding-bottom: 8px;
    justify-content: flex-start;
  }
}

.home-page .consumer-reservation {
  @include respond-to(mobile) {
    margin: 12px;
  }
}

.home-page .consumer-reservation:first-of-type {
  margin-left: 20px;
}

.home-page .consumer-reservation:last-of-type {
  margin-right: 20px;
}
