.datepicker-field {
  position: relative;
}

.datepicker-field input {
  position: absolute;
  z-index: 9;
  background: transparent;
  cursor: pointer;
}

.datepicker-field svg {
  position: absolute;
  cursor: pointer;
  z-index: 10;
  top: 17px;
  right: 19px;
}

.datepicker-field.datepicker-field--small svg {
  top: 13px;
  right: 15px;
}
