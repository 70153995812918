.consumer-transaction {
  border-bottom: 1px solid #E1E1E1;
  padding: 12px 0;
}

.consumer-transaction__row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  padding: 3px 0;
}

.consumer-transaction__row--first {
  padding: 0;
}

.consumer-transaction__id {
  font-size: 16px;
  font-weight: 500;
  color: #2A65A8;
}

.consumer-transaction__row-item {
  display: flex;
}

.consumer-transaction__row-item--green {
  color: #40B443;
}

.consumer-transaction__row-item--buttons {
  gap: 15px;
}
