.error-page__content {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;

  @include respond-to(mobile) {
    width: 90%;
  }
}

.error-page main {
  background: white;
}

.error_page__title {
  margin: 80px 0 0;
  font-size: 42px;
  font-weight: 500;
}

.error_page__subtitle {
  margin: 5px 0 0;
  font-size: 28px;
  font-weight: 500;
}

.error_page__message {
  margin: 17px 0 24px;
  font-size: 14px;
}

.error-page__buttons {
  display: flex;

  @include respond-to(mobile) {
    flex-direction: column;
  }
}

.error-page__button {
  margin-right: 15px;

  @include respond-to(mobile) {
    width: 100%;
    text-align: center;
    margin-bottom: 8px;
    margin-right: 0;
  }
}
