.product-page .page-container {
  display: flex;
  justify-content: center;
  font-size: 14px;

  @include respond-to(1050px) {
    flex-direction: column;
  }
  @include respond-to(mobile) {
    margin-left: 0;
    margin-right: 0;
    width: auto;
  }
}

.product-page__left {
  width: 100%;
  max-width: 750px;
  display: flex;
  flex-direction: column;
  margin-right: 24px;

  @include respond-to(mobile) {
    margin-right: 0;
  }
}

.product-page__right {
  min-width: 400px;

  @include respond-to(1050px) {
    min-width: unset;
    max-width: 750px;
    padding: 0;
    background-color: white;
  }
  @include respond-to(mobile) {
    margin-top: 0;
    padding: 0 20px;
  }
}

.product-page__right .page-card {
  @include respond-to(mobile) {
    background-color: $extra-lightgrey;
    border-radius: 4px;
  }
}

.product-page__right .page-card-title {
  display: flex;
  align-items: center;
  height: 48px;

  @include respond-to(mobile) {
    height: auto;
    font-size: 14px;
  }
}

.submit-button-container {
  padding: 0 30px 25px;
}

.product-page__reservation-container {
  margin: 20px 0;

  @include respond-to(mobile) {
    margin: 0;
  }
}

.product-page__dates-and-times {
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;

  @include respond-to(1350px) {
    flex-direction: column;
    padding: 13px 20px 40px;
  }
}

.page-card-content.product-page__dates-and-times {
  padding: 30px;
}

.product-page__reservation-title {
  @include respond-to(mobile) {
    padding-top: 38px;
    border: none;
    font-size: 20px;
    font-weight: 500;
  }
}

.product-page__reservation-datetime {
  width: 100%;
  margin: auto;
  flex: 1 1 0;
  border-radius: 4px;
  padding: 20px 10px;

  @include respond-to(1350px) {
    width: calc(100% - 20px);
  }
}

.product-page__reservation-datetime--readonly {
  @include respond-to(1350px) {
    display: flex;
  }
}

.product-page__reservation-datetime--yellow {
  border: 1px solid #FFC700;
  background-color: #FFFBF6;
}

.product-page__reservation-datetime-inputs > *:last-of-type {
  margin: 0;
}

.product-page__reservation-datetime-label {
  margin-bottom: 6px;
}

.product-page__reservation-datetime-inputs {
  display: flex;
  height: 40px;
}

.product-page__reservation-datetime-inputs input {
  margin-bottom: 2px;
}

.product-page__reservation-datetime-inputs > * {
  width: 100%;
  margin-right: 8px;
}

.product-page__reservation-datetime-divider {
  border-right: 1px solid lightgrey;
  margin: 0 20px;

  @include respond-to(1350px) {
    display: none;
  }
}

.product-page__reservation-details {
  display: flex;
  align-items: center;
  color: $textgrey;
  font-size: 14px;
  line-height: 20px;

  @include respond-to(mobile) {
    font-size: 12px;
  }
}

.product-page__reservation-details svg {
  margin-right: 14px;

  @include respond-to(mobile) {
    margin-right: 5px;
  }
}

.product-page__location-name {
  margin-top: 6px;
  color: $textblue;
  font-weight: 500;
  font-size: 18px;

  @include respond-to(mobile) {
    font-size: 16px;
  }
}

.product-page__location-details-container {
  padding-top: 0;
}

.product-page__location-details {
  color: $textblue;
  font-size: 18px;
  font-weight: 400;

  @include respond-to(mobile) {
    font-size: 16px;
  }
}

.product-page__share {
  font-size: 14px;
  font-weight: 400;
  border: none;
  cursor: pointer;
  padding-right: 60px;

  @include respond-to(mobile) {
    padding-right: 65px;
  }
}

.product-page__share-confirmation-message {
  position: absolute;
  right: 10px;
  top: -10px;
  border: 1px solid #C3E9C4;
  background-color: #E7F7E8;
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 10;
}

.page-card.product-page__share-container--mobile .product-page__share-confirmation-message {
  right: 0;
  top: -18px;
  border-radius: 3px;
}

.product-page__share-container {
  position: relative;
  z-index: 9;
}

.product-page__share-container--desktop {

  @include respond-to(1050px) {
    display: none;
  }
}

.page-card.product-page__share-container--mobile {
  display: none;
  margin: 20px 0;

  @include respond-to(1050px) {
    display: block;
    width: auto;
  }
  @include respond-to(mobile) {
    margin: 20px;
  }
}

.product-page__destination-header {
  cursor: pointer;

  @include respond-to(1000px) {
    border-bottom: none;
  }
}

.product-page__destination-container--with-areas {
  display: flex;

  @include respond-to(1000px) {
    flex-direction: column;
  }
}

.product-page__destination-input {
  width: 100%;
  padding: 30px;
  border-bottom: 1px solid $lightgrey;

  @include respond-to(1000px) {
    padding: 20px;
    border-bottom: none;
  }
}

.product-page__destination-input svg {
  height: 42px;
}

.product-page__destination-container--with-areas .product-page__destination-input {
  width: 50%;

  @include respond-to(1000px) {
    width: 100%;
  }
}

.product-page__destination-input-label {
  margin-bottom: 6px;
}

.product-page__destination-container--with-areas .product-page__destination-input--location-area {
  padding-right: 10px;

  @include respond-to(1000px) {
    padding-right: 20px;
  }
}

.product-page__destination-input--location {
  @include respond-to(1000px) {
    padding-bottom: 40px;
  }
}

.product-page__destination-container--with-areas .product-page__destination-input--location {
  padding-left: 10px;

  @include respond-to(1000px) {
    padding-left: 20px;
    padding-bottom: 40px;
  }
}

.product-page__summary {
  border-bottom: 1px solid $lightgrey;
  padding: 30px;

  @include respond-to(mobile) {
    padding-top: 20px;
  }
}

.product-page__summary,
.product-page__destination-input,
.product-page__location-details-container {
  box-sizing: border-box;
  max-height: 1000px;
  transition: all 0.3s ease-in-out;
}

.product-page__summary.hidden,
.product-page__destination-input.hidden,
.product-page__location-details-container.hidden {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
  overflow: hidden;
}

.product-page__summary-location {
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 16px;

  @include respond-to(mobile) {
    margin-bottom: 8px;
  }
}

.product-page__summary-location span {
  display: inline-block;
  margin-bottom: 5px;

  @include respond-to(mobile) {
    display: none;
  }
}

.product-page__summary-location .location-name {
  font-size: 16px;
}

.product-page__summary-location .location-address {
  font-size: 14px;
}

.product-page__summary-start-datetime {
  margin-bottom: 15px;
}

.product-page__summary-end-datetime {
  margin-top: 15px;
}

.product-page__summary-duration {
  display: flex;
  font-size: 12px;
}

.product-page__summary-duration svg {
  align-self: center;
}

.product-page__readable-summary-datetime {
  color: $textgrey;
}

.product-page__pricing-content {
  border-radius: 4px;

  @include respond-to(mobile) {
    padding: 0 20px 24px;
  }
}

.product-page__pricing-content--booking-extension {
  background: #F9F9F9;
  padding: 30px;
}

.product-page__pricing-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
}

.product-page__pricing-item--discount {
  border: 1px solid #C3E9C4;
  background: #E7F7E8;
  padding: 10px;
  border-radius: 4px;
}

.product-page__pricing-item--grey {
  color: #6B6B6B;
}

.product-page__pricing-item--small-text {
  font-size: 12px;
}

.product-page__pricing-details-button {
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #6B6B6B;
  margin-bottom: 15px;
}

.product-page__pricing-details-button-label {
  color: $primaryblue;
  width: fit-content;
  cursor: pointer;
}

.product-page__pricing-item-money {
  font-weight: 500;
}

.product-page__pricing-item-money--green {
  color: #40B443;
}

.product-page__pricing-item-money--big {
  font-size: 20px;
}

.product-page__pricing {
  padding: 20px 30px;

  @include respond-to(mobile) {
    padding: 26px 0 0;
  }
}

.product-page__location-details-address {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px;
  background-color: $extra-lightgrey;

  @include respond-to(mobile) {
    order: 2;
    margin: 0 -20px 0;
  }
}

.product-page__location-details-address svg {
  margin-right: 15px;
}

.product-page__location-details-name {
  margin: 40px 0 16px;
  font-size: 20px;
  font-weight: 500;

  @include respond-to(mobile) {
    margin-top: -30px;
  }
}

.product-page__location-details-name--desktop {
  display: block;

  @include respond-to(mobile) {
    display: none;
  }
}

.product-page__location-details-images {
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;

  @include respond-to(mobile) {
    flex-direction: column;
    order: 1;
    margin: 0 -20px;
  }
}

.product-page__location-map-header {
  @include respond-to(1000px) {
    border-top: 1px solid $lightgrey;
  }
}

.product-page__location-details-images .location-image-container,
.product-page__location-details-images .location-map-iframe {
  width: 49%;

  @include respond-to(mobile) {
    width: 100%;
    height: 185px;
  }
}

.product-page__location-details-images .location-image-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.product-page__location-details-images .location-name {
  display: none;
  padding: 30px;
  color: $textblue;
  font-size: 20px;
  font-weight: 500;
  width: auto;

  @include respond-to(mobile) {
    display: block;
  }
}

.product-page__location-details-images iframe {
  border: none;
  min-height: 228px;
}

.product-page__location-details-text {
  @include respond-to(mobile) {
    order: 3;
    margin-top: 30px;
  }
}

.product-page__location-details-features {
  @include respond-to(mobile) {
    order: 4;
  }
}

.product-page__button--desktop {
  margin-top: 20px;
}

.product-page__button--mobile {
  @include respond-to(mobile) {
    margin: 20px 0;
  }
}

.product-page__no-border--mobile {
  @include respond-to(mobile) {
    border: none;
  }
}

.product-page__reservation-form .input-error select {
  border-color: $red;
  background-color: $extra-lightred;
}

.product-page__dates-and-times .input-error {
  flex-basis: 100%;
}

.product-page__license-plate-container {
  margin: 20px 0;

  @include respond-to(mobile) {
    margin: 0;
  }
}

.product-page__license-plate-container .page-card-title {
  @include respond-to(mobile) {
    border-bottom: 0;
    font-size: 20px;
    font-weight: 500;
  }
}

.page-card-content--small {
  padding: 25px 30px 35px;

  @include respond-to(mobile) {
    padding: 15px 20px 40px;
  }
}

.license-plate__explanatory-text {
  margin-bottom: 20px;
}

.product-page__login-link {
  color: $primaryblue;
  cursor: pointer;
  margin-left: 25px;
  display: flex;
  align-items: center;

  @include respond-to(mobile) {
    margin: 0;
    padding-right: 10px;
  }
}

.product-page .license-plate-field {
  width: 166px;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @include respond-to(mobile) {
    margin: 0 0 20px;
  }
}

.product-page .license-plate-field input {
  height: 42px;
}

.product-page .license-plates-container {
  display: flex;

  @include respond-to(mobile) {
    flex-direction: column;
  }
}

.product-page .license-plates-container--wrapped {
  flex-wrap: wrap;
  flex-direction: row;
}

.license-plate-container {
  display: flex;
}

.product-page .license-plate {
  min-width: 116px;
  width: fit-content;
  border: 1px solid grey;
  border-radius: 4px;
  height: 40px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin-right: 10px;

  @include respond-to(mobile) {
    min-width: 140px;
  }
}

.product-page .license-plate--readonly {
  cursor: initial;
}

.product-page .license-plate:first-of-type {
  margin-left: 0;
}

.product-page .license-plate--selected,
.product-page .license-plate--selected input {
  border-color: $primaryblue;
  background-color: white;
  font-weight: 700;
}

.product-page .license-plate-field .form-field-error {
  font-weight: 400;
  margin-bottom: -16px;
}

.product-page .license-plate__value {
  margin-left: 40px;
  margin-right: 15px;
}

.license-plate-info {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.license-plate-info svg {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.license-plate-info__text {
  display: none;
  position: absolute;
  top: 15px;
  left: 35px;
  width: 350px;
  border-radius: 8px;
  padding: 15px;
  font-size: 14px;
  font-weight: 400;
  color: #6B6B6B;
  background-color: white;
  box-shadow: 0 1px 4px 0 rgba(209, 213, 216, 1);

  @include respond-to(1050px) {
    width: 80%;
    max-width: 350px;
  }
  @include respond-to(700px) {
    padding: 10px 15px;
  }
  @include respond-to(mobile) {
    width: 70%;
  }
}

.license-plate-info__text:after {
  content: "";
  position: absolute;
  top: 15px;
  left: -5px;
  height: 10px;
  width: 10px;
  background: white;
  transform: rotate(45deg);
  border-left: inherit;
  border-bottom: inherit;
  box-shadow: -4px 0 4px -2px rgba(209, 213, 216, 1), 0 4px 4px -2px rgba(209, 213, 216, 1);
}

.license-plate-info svg:hover + .license-plate-info__text {
  display: block;
}

.product-page .add-license-plate-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  width: 116px;
  min-width: fit-content;
  height: 42px;
  margin: auto 0 auto;

  @include respond-to(mobile) {
    width: 142px;
    margin: 15px 0;
  }
}

.product-page .license-plate-input-container input,
.product-page .license-plate-input-container .license-plate-field__car-icon {
  margin: 15px 0;
}

.product-page__readonly-dropdown {
  background-color: #ededed;
  border: 1px solid #bababa;
  border-radius: 4px;
  padding: 12px;
  font-size: 14px;
}

.product-page__dates-and-times--standalone {
  min-width: 375px;
  width: 55%;

  @include respond-to(tablet) {
    width: 100%;
  }
}

.product-page__expired-booking-warning {
  border: 1px solid #FFC700;
  border-radius: 4px;
  padding: 14px;
  margin-bottom: 20px;
  background-color: #FFFBF6;
  display: flex;

  @include respond-to(mobile) {
    margin-bottom: 0;
    border-radius: unset;
  }
}

.product-page__expired-booking-warning--major {
  border-color: #F19B94;
  background-color: #FDE8E7;
}

.product-page__expired-booking-warning svg {
  margin: 4px 14px 0 0;
}

.product-page__expired-booking-warning p {
  line-height: 1.4;
}

.product-page .discount-code-field {
  padding: 0 30px 30px;
}

.product-page .discount-code-field button {
  margin-top: 10px;
}

.product-page .discount-code-details {
  border: 1px solid #C3E9C4;
  border-radius: 4px;
  padding: 10px 14px;
  background-color: #E7F7E8;
  margin: 10px 0;
}

.product-page .remove-discount-code-button {
  width: fit-content;
  color: $primaryblue;
  cursor: pointer;
  font-size: 12px;
}

.product-page__original-planned-datetime {
  color: #6B6B6B;
  font-size: 12px;
  margin-top: 10px;
}

.product-page__original-planned-datetime--important {
  font-size: 16px;
  font-weight: 700;
  color: $textblack;
  margin-top: 20px;

  @include respond-to(1350px) {
    margin-top: 0;
    margin-left: 10px;
  }
}

.product-page__non-field-errors {
  padding: 0 30px 30px;
}
