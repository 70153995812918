.register-page__title {
  padding: 24px 30px;
  color: $textblack;
  border-bottom: 1px solid $lightgrey;
  background-color: white;
  font-weight: 500;
  font-size: 18px;

  @include respond-to(mobile) {
    margin: 32px 0;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    border: none;
    background-color: transparent;
  }
}

.register-page__forms {
  display: flex;
  padding: 60px 30px;
  color: $textblack;
  background-color: white;
  border-bottom: 1px solid $lightgrey;

  @include respond-to(mobile) {
    flex-direction: column;
    margin-bottom: 20px;
    padding: 0;
    background-color: transparent;
    border: none;
  }
}

.register-page__left-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 42px;
  padding-bottom: 40px;
  border-right: 1px solid $lightgrey;

  @include respond-to(mobile) {
    display: flex;
    flex-direction: column;
    border: none;
    padding-right: 0;
    padding-bottom: 0;
  }
}

.register-page__right-form {
  width: 100%;
  padding-left: 42px;
  padding-bottom: 40px;

  @include respond-to(mobile) {
    width: auto;
    padding: 0;
    margin-top: 20px;
    background-color: white;
  }
}

.register-page__right-form form {
  @include respond-to(mobile) {
    padding: 10px 20px 35px;
  }
}

.register-page__right-form .add-license-plate-btn.mobile-only {
  width: 50px;
}

.register-page__subtitle {
  margin: 20px 0 35px;
  font-weight: 500;

  @include respond-to(mobile) {
    margin-top: 0;
    padding: 20px;
    border-bottom: 1px solid $lightgrey;
  }
}

.register-page__info-label {
  display: block;
  margin-bottom: 30px;
  font-size: 14px;

  @include respond-to(mobile) {
    padding: 0 20px;
  }
}

.register-page__personal-info-fields,
.register-page__password-fields {
  @include respond-to(mobile) {
    background-color: white;
  }
}

.register-page__password-fields {
  @include respond-to(mobile) {
    margin-top: 20px;
  }
}

.register-page__inputs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include respond-to(mobile) {
    display: flex;
    flex-direction: column;
    padding: 0 20px 20px;
  }
}

.register-page__input {
  margin-bottom: 24px;
  width: calc(50% - 12px);

  @include respond-to(mobile) {
    margin-right: 0;
    width: 100%;
  }
}

.register-page__input:nth-child(2n) {
  margin-right: 0;
}

.register-page__create-btn-container {
  display: flex;
  justify-content: right;
  padding: 24px 30px;
  background-color: white;

  @include respond-to(mobile) {
    padding: 20px;
    margin-bottom: 60px;
  }
}

.register-page__create-btn-container > button {
  @include respond-to(mobile) {
    width: 100%;
  }
}

.password__char-count {
  position: absolute;
  height: 40px;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  margin-right: 16px;
  color: $textgrey;
  font-size: 14px;
}
