.checkout-confirmation__text {
  font-size: 14px;
  line-height: 20px;
}

.checkout-confirmation__text--margin-bottom {
  margin-bottom: 20px;
}

.checkout-confirmation__text--margin-bottom-mobile {
  @include respond-to(mobile) {
    margin-bottom: 25px;
  }
}

.checkout-confirmation__text--bold {
  font-weight: 700;
}

.checkout-confirmation__text--with-link a {
  color: $textblue;
}

.checkout-confirmation__contact-item-container {
  display: flex;
  align-items: center;
  border: 1px solid $lightgrey;
  padding: 20px;
  margin-bottom: 10px;
  color: black;
  text-decoration: none;

  @include respond-to(mobile) {
    background-color: white;
    border: none;
  }
}

.checkout-confirmation__contact-item-container svg {
  margin-right: 10px;
}

.checkout-confirmation__contact-item-container:last-child {
  margin-bottom: 0;
  @include respond-to(mobile) {
    margin-bottom: 30px;
  }
}

.checkout-confirmation__contact-item-container:first-child {
  margin-top: -30px;

  @include respond-to(mobile) {
    margin-top: 0;
  }
}

.checkout-confirmation__contact-content {
  @include respond-to(mobile) {
    background-color: transparent;
    padding: 0;
  }
}

.checkout-confirmation__contact {
  @include respond-to(mobile) {
    background-color: transparent;
  }
}

.checkout-confirmation__restart-button {
  margin: 25px 0 30px;
}
