.page__title {
  font-weight: 500;
  font-size: 28px;
  width: 100%;
  text-align: center;
  margin: 50px 0;
}

.page__mobile-banner-image {
  display: none;

  @include respond-to(mobile) {
    display: block;
    height: 124px;
    object-fit: cover;
  }
}

.page__mobile-title {
  display: none;

  @include respond-to(mobile) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    font-size: 22px;
    font-weight: 500;
  }
}

.page__mobile-back-button {
  display: none;

  @include respond-to(mobile) {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 42px;
    left: 20px;
    height: 30px;
    width: 30px;
  }
}

.page__hero-banner {
  max-height: 310px;
  width: 100%;
  object-fit: cover;

  @include respond-to(mobile) {
    height: 320px;
  }
}

.page__hero-banner-placeholder {
  height: 310px;
  background-color: $darkblue;
}

.container.page-container {
  display: flex;

  @include respond-to(mobile) {
    flex-direction: column;
  }
}

.container.page-container--relative {
  position: relative;
}

.container.page-container--column {
  flex-direction: column;
}

.page-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  margin-right: 20px;

  @include respond-to(mobile) {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.page-card--half-width {
  width: 50%;
  align-self: flex-start;

  @include respond-to(mobile) {
    width: 100%;
  }
}

.page-card--border {
  border: 1px solid $lightgrey;
  border-radius: 4px;
}

.page-card--transparent-bg {
  background-color: transparent;
}

.page-card-title {
  position: relative;
  flex-grow: 0;
  padding: 25px 30px;
  border-bottom: 1px solid $lightgrey;
  font-size: 18px;
  font-weight: 500;

  @include respond-to(mobile) {
    padding: 20px;
    font-size: 16px;
    font-weight: 400;
  }
}

.page-card-title--svg,
.page-card-title--svg > * {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-card-title--red {
  color: $darkred;
  background-color: $lightred;
  border-color: $pink;
}

.page-card-title--green {
  color: $darkgreen;
  background-color: $extra-lightgreen;
  border-color: $lightgreen;
}

.page-card-title--yellow {
  color: $darkyellow;
  background-color: $extra-lightyellow;
  border-color: $yellow;
}

.page-card-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 60px 30px;

  @include respond-to(mobile) {
    padding: 40px 20px;
  }
}

.page-card-content--less-vertical-padding {
  padding: 30px;
}

.page-card-content__section {
  padding: 18px 0;
  border-bottom: 1px solid #E5E5E5;
}

.page-card-content__section:last-of-type {
  border-bottom: 0;
}

.page-card-content__section-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
}

.page-card-content__section-text,
.page-card-content__section-edit-link {
  font-size: 14px;
  font-weight: 400;

}

.page-card-content__section-text {
  margin-bottom: 3px;
}

.page-card-content__help-text {
  color: #949494;
  margin-top: 20px;
}

.page-card-content__section-edit-link {
  margin-top: 25px;
}

.page-card-content__section-edit-link a {
  text-decoration: none;
  color: $textblue;
}

.page-card-title__arrow {
  position: absolute;
  display: flex;
  align-items: center;
  right: 30px;
  top: 0;
  height: 100%;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.page-card-title__arrow.rotate {
  transform: rotate(180deg);
}

.loaded .js-dropdown + * {
  max-height: 100vh;
  transition: all 0.3s ease-in-out;
}

.js-dropdown + .hidden {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
  overflow: hidden;
}

.page__text-link {
  display: block;
  margin-top: 27px;
  text-align: center;
  color: $textblue;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
}

.page__text-link:hover {
  text-decoration: underline;
}
