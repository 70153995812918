.consumer-reservations__title {
  margin-top: 50px;
  font-size: 28px;
  text-align: center;
  color: $textblack;
}

.consumer-reservations-tabs {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 15px 0 40px;
  cursor: pointer;
}

.consumer-reservations-tabs__item {
  color: $primaryblue;
  font-size: 14px;
  font-weight: 400;
  padding: 6px 10px;
  text-decoration: none;
}

.consumer-reservations-tabs__item--active {
  border-bottom: 2px solid $primaryblue;
}

.consumer-reservations__empty-list {
  color: $textblack;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
}
