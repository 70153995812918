.login-modal__form {
  padding: 30px;
}

.login-modal__explanatory-text {
  margin-bottom: 45px;
  font-size: 14px;
}

.login-modal__buttons-container.login-modal__buttons-container {
  margin-bottom: 0;
}
