.checkout-credentials-page {
  font-size: 14px;
}

.page-card--form-wrapper {
  height: fit-content;
}

.checkout-credentials__card-text {
  margin-bottom: 20px;
  padding: 0 30px;
}

.checkout-credentials__form-fields {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.checkout-credentials__resident-info,
.checkout-credentials__guest-info {
  max-width: 540px;
  padding: 0 13px 0 20px;
  margin: 15px 30px;
  outline: 1px solid #D9D9D9;
  cursor: pointer;

  @include respond-to(mobile) {
    margin-bottom: 25px;
  }
}

.checkout-credentials__resident-info {
  margin-top: 30px;
  padding-bottom: 10px;
}

.checkout-credentials__guest-info {
  margin-bottom: 40px;
}

.checkout-credentials__guest-info .checkout-credentials__form-field-pair:last-of-type {
  @include respond-to(mobile) {
    margin-bottom: 0;
  }
}

.checkout-credentials__resident-info {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.checkout-credentials__form-field-pair {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;

  @include respond-to(mobile) {
    flex-direction: column;
    padding: 0 30px;
  }
}

.checkout-credentials__form-field-pair:last-of-type {
  @include respond-to(mobile) {
    margin-bottom: 30px;
  }
}

.checkout-credentials__guest-info .checkout-credentials__form-field-pair {
  padding: 5px 0;
}

.checkout-credentials__form-field {
  width: 48%;

  @include respond-to(mobile) {
    width: 100%;
    padding: 10px 0;
  }
}

.checkout-credentials__form-field:last-child {
  @include respond-to(mobile) {
    margin-bottom: 0;
  }
}

.checkout-credentials__form-field--checkbox {
  width: 100%;
  border-top: 1px solid $lightgrey;
  padding: 60px 30px 10px 30px;
  margin-top: 30px;
}

.checkout-credentials__form-field--checkbox label {
  display: flex;
}

.checkout-credentials__form-field--checkbox input {
  margin-right: 6px;
}

.checkout-personal-info-form .label-text::before {
  margin-right: 4px;
}

.checkout-credentials__create-account-field {
  display: flex;
  margin: 25px 0;
  width: 100%;
}

.checkout-credentials__create-account-label {
  font-weight: 400;
  display: flex;
  align-items: end;
}

.consumer__personal-info {
  font-weight: 400;
}

.consumer__personal-info-row {
  display: flex;
  margin-bottom: 15px;
}

.consumer__personal-info-row svg {
  margin-right: 5px;
}

.edit-link-button {
  margin-top: 30px;
  color: $textblue;
  cursor: pointer;
}

.readonly-license-plates {
  display: flex;
}

.readonly-license-plate {
  width: fit-content;
  padding: 5px 10px;
  background-color: #F1F1F1;
  border-radius: 3px;
  margin: 10px 10px 10px 0;
}

.edit-consumer-account-form__buttons {
  display: flex;
  justify-content: flex-end;
  padding: 20px 30px;
  border-top: 1px solid $lightgrey;
}

.edit-consumer-account-form__buttons .button {
  margin-left: 15px;
}

.page-card-content--checkout-credentials-form {
  padding: 30px 0 0 0;

  @include respond-to(mobile) {
    padding-top: 20px;
  }
}

.checkout-credentials-page .form-fields-box-title {
  padding: 15px 0;
  font-size: 16px;
  font-weight: 500;
}

.selected-personal-info-box {
  padding-left: 13px;
  border-width: 0 0 0 7px;
  border-style: solid;
  border-color: #174EA0;
  outline-color: #174EA0;
}

.checkout-credentials-page .shopping-cart-overview {
  @include respond-to(mobile) {
    display: flex;
    flex-direction: column-reverse;
  }
}
