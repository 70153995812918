.language_modal__links {
  display: flex;
  flex-direction: column;
  padding: 20px 20px 35px;
}

.language_modal__link {
  color: $textblack;
  border: 1px solid $grey;
  padding: 15px 20px;
  margin-bottom: 10px;
  border-radius: 4px;
  text-decoration: none;
}

.language_modal__link:last-child {
  margin-bottom: 0;
}

.language_modal__link--selected {
  color: white;
  background-color: $textblue;
  border: none;
}
