.password-forgotten-modal__form {
  padding: 30px;
}

.password-forgotten-modal__explanatory-text {
  margin-bottom: 30px;
  font-size: 14px;
  font-weight: 400;
}

.password-forgotten-modal__form button {
  margin-top: 10px;
}

.password-forgotten-modal__confirmation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.password-forgotten-modal__confirmation-checkmark {
  padding: 60px;
}

.password-forgotten-modal__confirmation-text {
  padding: 0 50px 50px;
  text-align: center;

  @include respond-to(mobile) {
    width: 80%;
    text-align: center;
  }
}
