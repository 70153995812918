.personal-info-page .page-card-title {
  @include respond-to(mobile) {
    border-bottom: none;
    padding: 35px 20px 20px;
  }
}

.personal-info-page .page-card-content {
  padding: 0;
}

.personal-info-page .personal-info-form__fields {
  display: flex;
  flex-wrap: wrap;
}

.personal-info-page .personal-info-form__fields,
.personal-info-page .form-text {
  margin: 30px;

  @include respond-to(mobile) {
    margin: 20px;
  }
}

.personal-info-page .form-text {
  margin-top: 60px;

  @include respond-to(mobile) {
    display: none;
  }
}

.personal-info-page .form-field {
  width: 49%;

  @include respond-to(mobile) {
    width: 100%;
  }
}

.personal-info-page .form-field:nth-child(odd) {
  margin: 0 auto 20px 0;
}

.personal-info-page .form-field:nth-child(even) {
  margin: 0 0 20px auto;
}

.personal-info-form__bottom {
  margin-top: 70px;
  padding: 30px;
  border-top: 1px solid #E5E5E5;
  display: flex;
  justify-content: flex-end;

  @include respond-to(mobile) {
    padding: 20px;
    margin: 0 0 35px;
    border-top: none;
  }
}

.personal-info-form__bottom .button {
  margin-left: 10px;

  @include respond-to(mobile) {
    margin-left: 0;
    width: 100%;
  }
}

.personal-info-form__cancel-button {
  display: flex;
  justify-content: center;
  align-items: center;

  @include respond-to(mobile) {
    display: none;
  }
}
