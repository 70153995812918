.shopping-cart-item {
  margin-bottom: 20px;
}

.shopping-cart-item__location-name {
  color: $textblue;
  font-weight: 500;
  margin-bottom: 5px;
}

.shopping-cart-item__location-address {
  font-size: 14px;
  margin-bottom: 25px;
}

.shopping-cart-item__date {
  font-size: 14px;
  font-weight: 500;
}

.shopping-cart-item__date-friendly {
  color: $textgrey;
  font-weight: 400;
}

.shopping-cart-item__duration {
  display: flex;
  align-items: center;
  margin: 20px 0;
  font-size: 12px;
}

.shopping-cart-item__duration svg {
  margin-right: 10px;
}

.shopping-cart-item__total {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  margin-top: 40px;
  border-top: 1px solid $lightgrey;
  font-size: 14px;
}

.shopping-cart-item__total-without-discount {
  color: #6B6B6B;
  margin-right: 5px;
  position: relative;
}

.shopping-cart-item__total-without-discount::before {
  content: '';
  width: 75%;
  position: absolute;
  top: calc(50% - 1.5px);
  border-bottom: 1px solid #6B6B6B;
}

.shopping-cart-item__license-plate {
  margin: 25px 0;
}

.shopping-cart-item__edit-link {
  display: block;
  text-decoration: none;
  color: $textblue;
  font-size: 14px;
}

.shopping-cart-item__edit-link:hover {
  text-decoration: underline;
}


.shopping-cart-total__amount-to-pay {
  font-size: 20px;
}

.shopping-cart-total__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
}

.shopping-cart-total__row--grey {
  color: #6B6B6B;
}

.shopping-cart-total__vat {
  font-size: 12px;
  color: #6B6B6B;
}

.shopping-cart-total__discount {
  border: 1px solid #C3E9C4;
  border-radius: 4px;
  padding: 10px 14px;
  background-color: #E7F7E8;
  margin: 10px 0;
}
